<script>
import appConfig from "@/app.config";

import { notificationMethods } from "@/state/helpers";
import {getAuthorizationHeader} from "@/helpers/kiuru-http";
import axios from "axios";
import AuthService from "@/services/AuthService";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      let url = this.$config.BACKEND_BASE_URL + "ping";
      console.log("URL: " + url);
      let headers = getAuthorizationHeader();
      let currentRoute = this.$route.fullPath;
      const isAuthenticated = AuthService.isAuthenticated();

      if (!isAuthenticated && currentRoute !== "/login") {
        this.$router.push("/mlogin");
      }

      if (currentRoute !== "/mlogin" && currentRoute !== "/login") {
        axios.get(url, { headers })
            .then(response => {
              // Handle successful response, if needed
              console.log("PING RESPONSE: " + JSON.stringify(response.data));
            })
            .catch(error => {
              console.error("Error:", error);
              AuthService.logout();
              // Check for specific types of errors if needed
              if (error.response) {
                if (error.response.status === 500) {
                  this.$router.push("/mlogin");
                }
                if (error.response.status === 401) {
                  // Unauthorized, redirect to login
                  this.$router.push("/mlogin");
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received:", error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Request setup error:", error.message);
              }
            });
      }

      this.clearNotification();
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
  },
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
