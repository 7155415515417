export default {
  "navbar": {
    "search": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث..."])}
    },
    "dropdown": {
      "megamenu": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة الضخمة"])},
        "uicontent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكونات واجهة المستخدم"])},
          "list": {
            "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق مضئ"])},
            "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط التمرير"])},
            "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه لطيف"])},
            "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم"])},
            "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نماذج"])},
            "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجداول"])},
            "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم البيانية"])}
          }
        },
        "application": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيقات"])},
          "list": {
            "ecommerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التجارة الإلكترونية"])},
            "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
            "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاريع"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهام"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])}
          }
        },
        "extrapages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحات اضافية"])},
          "list": {
            "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط جانبي خفيف"])},
            "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط جانبي مضغوط"])},
            "horizontallayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخطيط الأفقي"])},
            "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمال صيانة"])},
            "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبا"])},
            "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الزمني"])},
            "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])}
          }
        }
      },
      "site": {
        "list": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيثب"])},
          "bitbucket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitbucket"])},
          "dribbble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراوغة"])},
          "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بصندوق الإسقاط"])},
          "mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريد الشمبانزي"])},
          "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثاقل"])}
        }
      },
      "notification": {
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعارات"])},
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
        "order": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلبك"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا دمجت عدة لغات القواعد"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل 3 دقائق"])}
        },
        "james": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيمس لومير"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيبدو الأمر مثل اللغة الإنجليزية المبسطة."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منذ 1 ساعة"])}
        },
        "item": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم شحن البند الخاص بك"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا دمجت عدة لغات القواعد"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل 3 دقائق"])}
        },
        "salena": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سالينا لايفيلد"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بصفتي صديقًا متشككًا في كامبريدج لأعمال الغرب."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منذ 1 ساعة"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل المزيد.."])}
      },
      "henry": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنري"])},
        "list": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
          "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محفظتي"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات"])},
          "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقفل الشاشة"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])}
        }
      }
    }
  },
  "menuitems": {
    "menu": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة طعام"])}
    },
    "dashboards": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحات المعلومات"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04"])},
      "list": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إفتراضي"])},
        "saas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساس"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشفير"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدونة"])}
      }
    },
    "layouts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التخطيطات"])},
      "list": {
        "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرضي"])},
        "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط جانبي خفيف"])},
        "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط جانبي مضغوط"])},
        "iconsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشريط الجانبي للرموز"])},
        "boxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخطيط محاصر"])},
        "coloredsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشريط الجانبي الملون"])},
        "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمودي"])},
        "lighttopbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط علوي خفيف"])},
        "coloredheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رأس ملون"])}
      }
    },
    "apps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيقات"])}
    },
    "calendar": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقويم"])}
    },
    "chat": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دردشة"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])}
    },
    "filemanager": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير الملفات"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])}
    },
    "ecommerce": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التجارة الإلكترونية"])},
      "list": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات"])},
        "productdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المنتج"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب #٪ s"])},
        "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الزبائن"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربة التسوق"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
        "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محلات"])},
        "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف منتج"])}
      }
    },
    "crypto": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشفير"])},
      "list": {
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محفظة نقود"])},
        "buy/sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شراء بيع"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
        "lending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإقراض"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلب #٪ s"])},
        "kycapplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق KYC"])},
        "icolanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICO Landing"])}
      }
    },
    "email": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "list": {
        "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق الوارد"])},
        "reademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قراءة البريد الإلكتروني"])},
        "template": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
          "list": {
            "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراء الأساسي"])},
            "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه البريد الإلكتروني"])},
            "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني لقوائم الدفع"])}
          }
        }
      }
    },
    "invoices": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فواتير"])},
      "list": {
        "invoicelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الفاتورة"])},
        "invoicedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الفاتورة"])}
      }
    },
    "projects": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاريع"])},
      "list": {
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكة المشاريع"])},
        "projectlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المشاريع"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص المشروع"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلق جديد إبداع جديد"])}
      }
    },
    "tasks": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهام"])},
      "list": {
        "tasklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المهام"])},
        "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجلس كانبان"])},
        "createtask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء مهمة"])}
      }
    },
    "contacts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
      "list": {
        "usergrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكة المستخدم"])},
        "userlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المستخدم"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])}
      }
    },
    "blog": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدونة"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
      "list": {
        "bloglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المدونة"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شبكة المدونة"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المدونة"])}
      }
    },
    "pages": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفحات"])}
    },
    "authentication": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصادقة"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جديد"])},
      "list": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
        "recoverpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستعادة كلمة المرور"])},
        "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقفل الشاشة"])},
        "login-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول 2"])},
        "register-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل 2"])},
        "recoverpwd-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور 2"])},
        "lockscreen-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاشة القفل 2"])},
        "confirm-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد البريد"])},
        "confirm-mail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد البريد 2"])},
        "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد بواسطة البريد الالكتروني"])},
        "verification-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من البريد الإلكتروني 2"])},
        "verification-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من خطوتين"])},
        "verification-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق بخطوتين 2"])}
      }
    },
    "utility": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة"])},
      "list": {
        "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة المبتدئين"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمال صيانة"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجدول الزمني"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسئلة الشائعة"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسعير"])},
        "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ 404"])},
        "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ 500"])},
        "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبا"])}
      }
    },
    "components": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكونات"])}
    },
    "uielements": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر واجهة المستخدم"])},
      "list": {
        "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنبيهات"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أزرار"])},
        "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البطاقات"])},
        "carousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دائري"])},
        "dropdowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هبوط قطرة"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جريد"])},
        "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صور"])},
        "modals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشروط"])},
        "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريط التمرير"])},
        "progressbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشرطة التقدم"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنصر نائب"])},
        "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبيه لطيف"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علامات التبويب والأكورديون"])},
        "typography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطباعة"])},
        "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنرال لواء"])},
        "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الألوان"])},
        "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صندوق مضئ"])},
        "cropper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة كروبر"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرج"])}
      }
    },
    "forms": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نماذج"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "list": {
        "elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناصر النموذج"])},
        "layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تخطيطات النموذج"])},
        "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من صحة النموذج"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج متقدم"])},
        "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محرر النماذج"])},
        "fileupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف النموذج"])},
        "repeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكرر النموذج"])},
        "wizard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معالج النماذج"])},
        "mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قناع النموذج"])}
      }
    },
    "tables": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجداول"])},
      "list": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجداول الأساسية"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدول متقدم"])}
      }
    },
    "charts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم البيانية"])},
      "list": {
        "apex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخطط أبيكس"])},
        "chartjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخطط Chartjs"])},
        "chartist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخطط رسومي"])},
        "echart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مخطط E"])}
      }
    },
    "icons": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيقونات"])},
      "list": {
        "boxicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxicons"])},
        "materialdesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصميم المادي"])},
        "dripicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dripicons"])},
        "fontawesome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخط رائع"])}
      }
    },
    "maps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خرائط"])},
      "list": {
        "googlemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خرائط جوجل"])},
        "leafletmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خرائط النشرة"])}
      }
    },
    "multilevel": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متعدد المستويات"])},
      "list": {
        "level1": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى 1.1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى 1.2"])},
          "level2": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى 2.1"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوى 2.2"])}
          }
        }
      }
    }
  }
}