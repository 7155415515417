import BaseService from "@/services/BaseService";

class LogsService extends BaseService {

    constructor(config) {
        super(config);
    }

    /**
     * Get audit logs by query
     * @returns {Promise<*>}
     * @param requestUrl - limit, startAt, startdate, enddate, targetType, targetValue, msisdn, route
     */
    async getAuditLogs(requestUrl) {
        return this.get("/auditlog/list" + requestUrl);
    }

    /**
     * Get MSS logs by query
     * @returns {Promise<*>}
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, route
     */
    async getMssLogs(requestUrl) {
        return this.get("/mss/logs" + requestUrl);
    }

    /**
     * Get MSS logs for user specified with MSISDN
     * @param msisdn
     * @returns {Promise<*>}
     */
    async getMssLogsByMsisdn(msisdn) {
        return this.get("/mss/logs/" + msisdn);
    }

    /**
     * Get all notification logs
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, msisdn, route
     * @returns {Promise<*>}
     */
    async getNotificationLogs(requestUrl) {
        return this.get("/notification/logs" + requestUrl);
    }

    /**
     * Get notification logs specified by MSISDN
     * @returns {Promise<*>}
     * @param requestUrl
     */
    async getNotificationLogsByMsisdn(requestUrl) {
        return this.get("/notification/logs/" + requestUrl);
    }

    /**
     * Get all MREG logs
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, route
     * @returns {Promise<*>}
     */
    async getMregLogs(requestUrl) {
        return this.get("/mreg/logs" + requestUrl);
    }

    /**
     * Get MREG logs by MSISDN
     * @param msisdn
     * @returns {Promise<*>}
     */
    async getMregLogsByMsisdn(msisdn) {
        return this.get("/mreg/logs/" + msisdn);
    }

    /**
     * Get all alauda logs
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, route
     * @returns {Promise<*>}
     */
    async getAlaudaLogs(requestUrl){
        return this.get("/alauda/logs" + requestUrl);
    }

    /**
     *
     * @param msisdn
     * @param query - limit, startat, start (date), end (date)
     * @returns {Promise<*>}
     */
    async getAlaudaLogsByMsisdn(msisdn, query) {
        return this.get("/alauda/logs/" + msisdn + query);
    }

    /** Get OTA logs by query
     * @returns {Promise<*>}
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, msisdn, messageType, route
     */
    async getOtaLogs(requestUrl) {
        return this.get("/ota/logs" + requestUrl);
    }

    /**
     * Get OTA logs by MSISDN and query
     * @param msisdn
     * @param query - start, end, limit, startAt
     * @returns {Promise<*>}
     */
    async getOtaLogsByMsisdn(msisdn, query) {
        return this.get("/ota/logs/" + msisdn + query);
    }
}

export default LogsService;