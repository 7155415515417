import BaseService from "@/services/BaseService";

class OtaService extends BaseService {

    constructor(config) {
        super(config);
    }

    /**
     * Get a list of OTA services
     * @returns {Promise<*>}
     */
    getOtaServices() {
        return this.get("/ota/services");
    }

    /**
     * Update OTA service
     * @param payload - otaid, state
     * @returns {Promise<*>}
     */
    updateOtaService(payload) {
        return this.post("/ota/service/update", payload);
    }

    getOtaStats() {
        return this.get("/stats/ota");
    }

    /**
     * Get OTA file provision list
     * @returns {Promise<*>}
     */
    getOtaRecordsList() {
        return this.get("/ota/records/list");
    }

    /**
     * Update OTA file provision record details
     * @param payload - otabatchid, filename, otafilenotes
     * @returns {Promise<*>}
     */
    updateOtaRecord(payload) {
        return this.post("/ota/records/update", payload);
    }

}

export default OtaService;