import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n"
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3"

import Maska from 'maska'

import BootstrapVueNext from 'bootstrap-vue-next'
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'

/*
import "../src/assets/css/bootstrap-vue.css";
import "../src/assets/css/global.css";
 */

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from "./state/store";
import "../src/design/app.scss";

import SimService from "@/services/SimService.js";
import AfeService from "@/services/AfeService.js";
import ApService from "@/services/ApService.js";
import CaService from "@/services/CaService.js"
import LogsService from "@/services/LogsService";
import MobileUserService from "@/services/MobileUserService";
import OtaService from "@/services/OtaService";
import RsspService from "@/services/RsspService";
import WebUserService from "@/services/WebUserService";
import MiscService from "@/services/MiscService";
import MsspService from "@/services/MsspService";

const app = createApp(App)

// Fetch config.json
fetch(`${process.env.BASE_URL}config.json`)
    .then(response => response.json())
    .then(config => {
        // Set the config available for every component
        app.config.globalProperties.$config = config

        // Add services to be globally available (to set base URL only once in the whole project)
        app.config.globalProperties.$simService        = new SimService(config);
        app.config.globalProperties.$afeService        = new AfeService(config);
        app.config.globalProperties.$apService         = new ApService(config);
        app.config.globalProperties.$caService         = new CaService(config);
        app.config.globalProperties.$logsService       = new LogsService(config);
        app.config.globalProperties.$mobileUserService = new MobileUserService(config);
        app.config.globalProperties.$otaService        = new OtaService(config);
        app.config.globalProperties.$rsspService       = new RsspService(config);
        app.config.globalProperties.$webUserService    = new WebUserService(config);
        app.config.globalProperties.$miscService       = new MiscService(config);
        app.config.globalProperties.$msspService       = new MsspService(config);

        app.use(store)
        app.use(router)
        app.use(require('vue-chartist'))
        app.use(VueApexCharts)
        app.use(vClickOutside)
        app.use(Maska)
        app.use(i18n)
        app.use(BootstrapVueNext)
        app.use(Vue3FormWizard)
        app.mount('#app')
    })
    .catch(error => {
        console.error('Error fetching config.json:', error);
    });
