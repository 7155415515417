import axios from 'axios';

class BaseService {
    constructor(config) {
        this.apiClient = axios.create({
            baseURL: config.BACKEND_BASE_URL,
            withCredentials: false,
            headers: {
                'Accept': 'application/json',
            }
        });

        this.apiClient.interceptors.request.use(
            config => {
                const token = localStorage.getItem('jwt');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        this.apiClient.interceptors.response.use(
            response => response,
            error => Promise.reject(error)
        );
    }

    async handleResponse(promise) {
        try {
            const response = await promise;
            return {
                ...response.data
            };
        } catch (error) {
            console.log("Got error1: " + JSON.stringify(error))
            console.log("Got error2: " + JSON.stringify(error.response))

            return {
                success: false,
                message: error.response?.data?.message || 'An error occurred',
                details: error.response?.data?.details || error.message,
                code: error.response?.data?.code || "900",
                httpCode: error.response?.status,
                contentType: error.response?.headers["content-type"]
            };
        }
    }

    async get(resource) {
        return this.handleResponse(this.apiClient.get(resource));
    }

    async post(resource, data) {
        return this.handleResponse(this.apiClient.post(resource, data));
    }

    async put(resource, data) {
        return this.handleResponse(this.apiClient.put(resource, data));
    }

    async delete(resource) {
        return this.handleResponse(this.apiClient.delete(resource));
    }

}

export default BaseService;