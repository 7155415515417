import BaseService from "@/services/BaseService";

/**
 * Web users are users that authenticate with username/password, not MSISDN
 */
class WebUserService extends BaseService {

    constructor(config) {
        super(config);
    }

    /**
     * Creates a new web user
     * @param payload - username, password, email, status, role
     * @returns {Promise<*>}
     */
    async createUser(payload) {
        return this.post("/user/create", payload);
    }

    /**
     * Deletes user by User ID
     * @param userId
     * @returns {Promise<*>}
     */
    async deleteUserByUserId(userId) {
        return this.post("/user/delete/id/" + userId);
    }

    /**
     * Deletes user by username
     * @param username
     * @returns {Promise<*>}
     */
    async deleteUserByUsername(username) {
        return this.post("/user/delete/" + username);
    }

    /**
     * Gets a single user by username
     * @param username
     * @returns {Promise<*>}
     */
    async getSingleUserByUsername(username) {
        return this.get("/user/get/" + username);
    }

    /**
     * Gets all users that matches the uqery
     * @param requestUrl - skipcache, limit, startAt, route
     * @returns {Promise<*>}
     */
    async getUsersByQuery(requestUrl) {
        return this.get("/user/list" + requestUrl);
    }

    /**
     * Updates a single user
     * @param payload - username, newusername, password, newpassword, email, roles, status
     * @returns {Promise<*>}
     */
    async updateUser(payload) {
        return this.post("/user/update", payload);
    }

}

export default WebUserService;