import BaseService from "@/services/BaseService";

class RsspService extends BaseService {
    constructor(config) {
        super(config);
    }

    /**
     * Creates a new RSSP client
     * @param payload - msisdn, apid, customer, send_sms, created_dt, client_type
     * @returns {Promise<*>}
     */
    async createRsspClient(payload) {
        return this.post("/rssp/client/create", payload);
    }

    /**
     * Delete an existing RSSP client.
     * @param payload - msisdn, apid
     * @returns {Promise<*>}
     */
    async deleteRsspClient(payload){
        return this.post("/rssp/client/delete", payload);
    }

    /**
     * Generate an API Key for RSSP client
     * @param payload - msisdn, apid, send_sms
     * @returns {Promise<*>}
     */
    async generateApiKey(payload) {
        return this.post("/rssp/client/generateapikey", payload);
    }

    /**
     * Get all RSSP clients that match the query
     * @param requestUrl - skipcache, limit, startat, msisdn, route
     * @returns {Promise<*>}
     */
    async getRsspClients(requestUrl) {
        return this.get("/rssp/client/list" + requestUrl);
    }

}

export default RsspService;