import BaseService from './BaseService.js';

class SimService extends BaseService {
    constructor(config) {
        super(config);
    }

    /**
     * Sends a POST request to create single SIM card
     * @param payload - imsi, msisdn, iccid, wpkiprofile, kidkey, kickey, dekkey, keyindex, otacounter
     * @returns {Promise<*>}
     */
    async createSimCard(payload) {
        return this.post("/sim/create", payload);
    }

    /**
     * Create a new OTA key with keyData
     * @param payload - imsi, kidkey, kickey, dekkey, keyindex, otacounter
     * @returns {Promise<*>}
     */
    async createOtaKey(payload) {
        return this.post("/sim/createotakey", payload);
    }

    async deleteOtaKey(payload){
        return this.post("/sim/deleteotakey", payload);
    }

    /**
     * Deletes SIM card by IMSI value
     * @param imsi
     * @returns {Promise<*>}
     */
    async deleteSimByImsi(imsi){
        return this.post("/sim/delete/imsi/" + imsi);
    }

    /**
     * Deletes SIM card by MSISDN value
     * @param msisdn
     * @returns {Promise<*>}
     */
    async deleteSimByMsisdn(msisdn) {
        return this.post("/sim/delete/" + msisdn);
    }

    /**
     * Gets a single SIM card by ICCID value
     * @param iccid
     * @returns {Promise<*>}
     */
    async getSimByIccid(iccid) {
        return this.get("/sim/get/iccid/" + iccid);
    }

    /**
     * Gets a single SIM card by IMSI value
     * @param imsi
     * @returns {Promise<*>}
     */
    async getSimByImsi(imsi) {
        return this.get("/sim/get/imsi/" + imsi);
    }

    /**
     * Gets a single SIM card by MSISDN value
     * @param msisdn
     * @returns {Promise<*>}
     */
    async getSimByMsisdn(msisdn) {
        return this.get("/sim/get/" + msisdn);
    }

    /**
     * Get list of all SIM Cards
     * @param requestUrl - skipcache, limit, startAt, targetType, targetValue, route
     * @returns {Promise<*>}
     */
    async getSimCardsByWpkiProfile(requestUrl) {
        return this.get("/sim/list" + requestUrl)
    }

    /**
     * Uploads OTA file and creates SIM cards
     * @param payload - file, wpkiprofile, operatorcode, filetype, filename, overwrite, transferkeyfactoryname
     * @returns {Promise<*>}
     */
    async uploadSimFile(payload) {
        return this.post("/sim/upload", payload);
    }

}

export default SimService;