
/**
 * Used for authorizing requests.
 * TODO: Solve storing the JWT in a secure way
 * @returns {{Authorization: string}}
 */
export function getAuthorizationHeader() {
    let jwt = localStorage.getItem("jwt");
    if (jwt) {
        return {
            "Authorization": `Bearer ${jwt}`
        }
    }
}


