import BaseService from "@/services/BaseService";

class MiscService extends BaseService {

    constructor(config) {
        super(config);
    }

    async getStats() {
        return this.get("/stats");
    }

    async testSignPdf(pdfRawData, msisdn, sscd) {
        if (!msisdn) return Promise.reject(new Error("No MSISDN given"));
        if (!pdfRawData) return Promise.reject(new Error("No PDF raw data set"));

        const url = "/test/sign";
        const splitData = pdfRawData.split(',');
        const pdfBase64 = splitData[1];


        const params = new URLSearchParams();
        params.append("doc", pdfBase64);
        params.append("msisdn", msisdn);
        params.append("sscd", sscd);

        return this.post(url, params, "application/x-www-form-urlencoded;charset=utf-8");
    }

    /**
     * Authentication test for SIM and App
     * @param payload - type, msisdn, message, sscd
     * @returns {Promise<*>}
     */
    async testAuthenticate(payload) {
        return this.post("/test/authn", payload);
    }

    /**
     * Execute MUTK command
     * @param payload - command
     * @returns {Promise<*>}
     */
    async executeMutk(payload) {
        return this.post("/mutk/execute", payload);
    }

    /**
     * Execute SIGTK command
     */
    async executeSigtk(payload) {
        return this.post("/sigtk/execute", payload);
    }

}

export default MiscService;