import BaseService from './BaseService.js';


class AfeService extends BaseService {
    constructor(config) {
        super(config);
    }

    /**
     * Get PBY logs
     * @param requestUrl - skipcache, limit, startAt, startdate, enddate, targetType, targetValue, msisdn, route
     * @returns {Promise<*>}
     */
    async getAfeLogs(requestUrl) {
        return this.get("/afe/logs" + requestUrl);
    }

    async getAfeLogsByMsisdn(msisdn) {
        return this.get("/afe/logs/" + msisdn);
    }

}

export default AfeService;