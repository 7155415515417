import BaseService from "@/services/BaseService";

class CaService extends BaseService {

    constructor(config) {
        super(config);
    }

    //TODO: this is not properly implemented in backend
    getCert(caUri) {
        return this.get("/ca/cacert" + caUri);
    }

    /**
     * Gets certificate revocation list by CRL label
     * @param crlLabel
     * @returns {Promise<*>}
     */
    getCrl(crlLabel) {
        return this.get("/ca/crl?crl_label=" + crlLabel);
    }

    /**
     * Refreshes certificate revocation list by label
     * @param crlLabel
     * @returns {Promise<*>}
     */
    refreshCrl(crlLabel) {
        return this.post("/ca/crlrefresh?crl_label=" + crlLabel, null);
    }

    /**
     * Gets list of certificate authorities. This method does not take in query
     */
    getCaList() {
        return this.get("/ca/list")
    }

    /**
     * Gets a list of certificate authorities. Requires a query in parameters
     * @param requestUrl - skipcache, limit, startAt, targetType, targetValue, ca_name, path
     * @returns {Promise<*>}
     */
    getCaListByQuery(requestUrl) {
        return this.get("/ca/list" + requestUrl);
    }

    /**
     * Test TSP connection, this is not tied to any particular CA and will be the same for all CA:s
     */
    async testTspConnection() {
        return this.post("/test/tsp");
    }
}

export default CaService;