export default {
  "navbar": {
    "search": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar..."])}
    },
    "dropdown": {
      "megamenu": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mega menú"])},
        "uicontent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Componentes de la IU"])},
          "list": {
            "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caja ligera"])},
            "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control deslizante de rango"])},
            "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta dulce"])},
            "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación"])},
            "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formas"])},
            "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesas"])},
            "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos"])}
          }
        },
        "application": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicaciones"])},
          "list": {
            "ecommerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercio electrónico"])},
            "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
            "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])}
          }
        },
        "extrapages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Páginas extra"])},
          "list": {
            "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral ligera"])},
            "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral compacta"])},
            "horizontallayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño horizontal"])},
            "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento"])},
            "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente"])},
            "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cronograma"])},
            "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])}
          }
        }
      },
      "site": {
        "list": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub"])},
          "bitbucket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitbucket"])},
          "dribbble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regatear"])},
          "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropbox"])},
          "mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chimpancé de correo"])},
          "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floja"])}
        }
      },
      "notification": {
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
        "order": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pedido es realizado"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si varios idiomas fusionan la gramática"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace 3 minutos"])}
        },
        "james": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James Lemire"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parecerá un inglés simplificado."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace 1 hora"])}
        },
        "item": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your item is shipped"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si varios idiomas fusionan la gramática"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace 3 minutos"])}
        },
        "salena": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salena Layfield"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a skeptical Cambridge friend of mine occidental."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace 1 hora"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga más.."])}
      },
      "henry": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henry"])},
        "list": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
          "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi billetera"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones"])},
          "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear pantalla"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cerrar sesión"])}
        }
      }
    }
  },
  "menuitems": {
    "menu": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú"])}
    },
    "dashboards": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableros"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04"])},
      "list": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defecto"])},
        "saas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saas"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])}
      }
    },
    "layouts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseños"])},
      "list": {
        "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral ligera"])},
        "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral compacta"])},
        "iconsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral de iconos"])},
        "boxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño en caja"])},
        "coloredsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lateral de color"])},
        "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
        "lighttopbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra superior ligera"])},
        "coloredheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encabezado de color"])}
      }
    },
    "apps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicaciones"])}
    },
    "calendar": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario"])}
    },
    "chat": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charla"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])}
    },
    "filemanager": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradora de archivos"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])}
    },
    "ecommerce": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercio electrónico"])},
      "list": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
        "productdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle del producto"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
        "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carro"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisa"])},
        "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiendas"])},
        "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar producto"])}
      }
    },
    "crypto": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto"])},
      "list": {
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billetera"])},
        "buy/sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra venta"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiar"])},
        "lending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préstamo"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
        "kycapplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud KYC"])},
        "icolanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aterrizaje ICO"])}
      }
    },
    "email": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "list": {
        "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bandeja de entrada"])},
        "reademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer el correo electrónico"])},
        "template": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
          "list": {
            "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción básica"])},
            "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico de alerta"])},
            "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico de Facturas"])}
          }
        }
      }
    },
    "invoices": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturas"])},
      "list": {
        "invoicelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de facturas"])},
        "invoicedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle de factura"])}
      }
    },
    "projects": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyectos"])},
      "list": {
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuadrícula de proyectos"])},
        "projectlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de proyectos"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del proyecto"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva"])}
      }
    },
    "tasks": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])},
      "list": {
        "tasklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tareas"])},
        "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Board"])},
        "createtask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear tarea"])}
      }
    },
    "contacts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
      "list": {
        "usergrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuadrícula de usuario"])},
        "userlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de usuarios"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])}
      }
    },
    "blog": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])},
      "list": {
        "bloglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de blogs"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog Grid"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del blog"])}
      }
    },
    "pages": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Páginas"])}
    },
    "authentication": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva"])},
      "list": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
        "recoverpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
        "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear pantalla"])},
        "login-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión 2"])},
        "register-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse 2"])},
        "recoverpwd-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña 2"])},
        "lockscreen-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear pantalla 2"])},
        "confirm-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar correo"])},
        "confirm-mail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar correo 2"])},
        "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificacion de email"])},
        "verification-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificacion de email 2"])},
        "verification-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de dos pasos"])},
        "verification-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de dos pasos 2"])}
      }
    },
    "utility": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidad"])},
      "list": {
        "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página de inicio"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cronograma"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
        "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
        "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 500"])},
        "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente"])}
      }
    },
    "components": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Componentes"])}
    },
    "uielements": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de la IU"])},
      "list": {
        "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botones"])},
        "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas"])},
        "carousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrusel"])},
        "dropdowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listas deplegables"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuadrícula"])},
        "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imágenes"])},
        "modals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalidades"])},
        "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control deslizante de rango"])},
        "progressbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barras de progreso"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcador de posición"])},
        "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta dulce"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pestañas y acordeones"])},
        "typography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipografía"])},
        "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vídeo"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colores"])},
        "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caja ligera"])},
        "cropper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recortador de imagen"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajón"])}
      }
    },
    "forms": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formas"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "list": {
        "elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos de formulario"])},
        "layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseños de formulario"])},
        "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación de formulario"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario avanzado"])},
        "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editora de formularios"])},
        "fileupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir archivo de formulario"])},
        "repeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetidor de forma"])},
        "wizard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistente de formulario"])},
        "mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máscara de forma"])}
      }
    },
    "tables": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesas"])},
      "list": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablas Básicas"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesa avanzada"])}
      }
    },
    "charts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos"])},
      "list": {
        "apex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de Apex"])},
        "chartjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartjs Chart"])},
        "chartist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartist Chart"])},
        "echart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico E"])}
      }
    },
    "icons": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Íconos"])},
      "list": {
        "boxicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxicones"])},
        "materialdesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de materiales"])},
        "dripicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dripicons"])},
        "fontawesome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font awesome"])}
      }
    },
    "maps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapas"])},
      "list": {
        "googlemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mapas de Google"])},
        "leafletmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapas de folleto"])}
      }
    },
    "multilevel": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi nivel"])},
      "list": {
        "level1": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel 1.1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel 1.2"])},
          "level2": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel 2.1"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2.2"])}
          }
        }
      }
    }
  }
}