import BaseService from "@/services/BaseService";

class MsspService extends BaseService {
    constructor(config) {
        super(config);
    }

    /**
     * List MSSP Mesh Members.
     * @returns {Promise<*>}
     * @param eid
     */
    async getMsspMeshList(eid) {
        if (eid !== null) {
            return this.get("/mssp/list/mesh?mssp=" + eid + "&skipcache=true");
        } else {
            return this.get("/mssp/list/mesh");
        }
    }

    /**
     * List MSSP Clusters.
     * @returns {Promise<*>}
     * @param query - name
     */
    async getMsspClusterList(query){
        if (query !== null) {
            return this.get("/mssp/list/cluster" + query);
        } else {
            return this.get("/mssp/list/cluster");
        }

    }

    /**
     * List Service Stats (configs and certs left out)
     */
    async getServiceStats(query) {
        return this.get("mssp/list/servicestats");
    }

    /**
     * Generate an API Key for RSSP client
     * @param payload - clien_certificate, msspuri
     * @returns {Promise<*>}
     */
    async createMsspCertificate(payload) {
        return this.post("/mssp/cert/create", payload);
    }

    /**
     * Get all RSSP clients that match the query
     * @returns {Promise<*>}
     * @param payload - msspuri, certid
     */
    async deleteMsspCertificate(payload) {
        return this.post("/mssp/cert/delete", payload);
    }

}

export default MsspService;