export default {
  "navbar": {
    "search": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search..."])}
    },
    "dropdown": {
      "megamenu": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mega Menu"])},
        "uicontent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Components"])},
          "list": {
            "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightbox"])},
            "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Slider"])},
            "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweet Alert"])},
            "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
            "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
            "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tables"])},
            "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])}
          }
        },
        "application": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
          "list": {
            "ecommerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecommece"])},
            "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
          }
        },
        "extrapages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Pages"])},
          "list": {
            "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Sidebar"])},
            "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact Sidebar"])},
            "horizontallayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal Layout"])},
            "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
            "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
            "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
            "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])}
          }
        }
      },
      "site": {
        "list": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub"])},
          "bitbucket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitbucket"])},
          "dribbble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dribbble"])},
          "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropbox"])},
          "mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Chimp"])},
          "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack"])}
        }
      },
      "notification": {
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
        "order": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order is placed"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If several languages coalesce the grammar"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 min ago"])}
        },
        "james": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James Lemire"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It will seem like simplified English."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hours ago"])}
        },
        "item": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your item is shipped"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If several languages coalesce the grammar"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 min ago"])}
        },
        "salena": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salena Layfield"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a skeptical Cambridge friend of mine occidental."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hours ago"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More.."])}
      },
      "henry": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henry"])},
        "list": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
          "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Wallet"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
          "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock screen"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
        }
      }
    }
  },
  "menuitems": {
    "menu": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
    },
    "dashboards": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04"])},
      "list": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
        "saas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saas"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])}
      }
    },
    "layouts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layouts"])},
      "list": {
        "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
        "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Sidebar"])},
        "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compact Sidebar"])},
        "iconsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons Sidebar"])},
        "boxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxed Layout"])},
        "coloredsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colored Sidebar"])},
        "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical"])},
        "lighttopbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light Topbar"])},
        "coloredheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colored Header"])}
      }
    },
    "apps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])}
    },
    "calendar": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])}
    },
    "chat": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])}
    },
    "filemanager": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Manager"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])}
    },
    "ecommerce": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecommerce"])},
      "list": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
        "productdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Detail"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
        "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shops"])},
        "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])}
      }
    },
    "crypto": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
      "list": {
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
        "buy/sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/sell"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
        "lending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lending"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
        "kycapplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC Application"])},
        "icolanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICO Landing"])}
      }
    },
    "email": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "list": {
        "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
        "reademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Email"])},
        "template": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
          "list": {
            "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Action"])},
            "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert Email"])},
            "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Email"])}
          }
        }
      }
    },
    "invoices": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "list": {
        "invoicelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice List"])},
        "invoicedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Detail"])}
      }
    },
    "projects": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "list": {
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects Grid"])},
        "projectlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects List"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Overview"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New"])}
      }
    },
    "tasks": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
      "list": {
        "tasklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task List"])},
        "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Board"])},
        "createtask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Task"])}
      }
    },
    "contacts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "list": {
        "usergrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Grid"])},
        "userlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User List"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
      }
    },
    "blog": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "list": {
        "bloglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog List"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog Grid"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog Details"])}
      }
    },
    "pages": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])}
    },
    "authentication": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "list": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "login-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login 2"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
        "register-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register 2"])},
        "recoverpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password"])},
        "recoverpwd-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password 2"])},
        "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock screen"])},
        "lockscreen-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock screen 2"])},
        "confirm-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Mail"])},
        "confirm-mail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Mail 2"])},
        "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email verification"])},
        "verification-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email verification 2"])},
        "verification-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two step verification"])},
        "verification-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two step verification 2"])}
      }
    },
    "utility": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility"])},
      "list": {
        "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starter Page"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
        "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])},
        "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 500"])},
        "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])}
      }
    },
    "components": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Components"])}
    },
    "uielements": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI Elements"])},
      "list": {
        "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
        "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards"])},
        "carousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carousel"])},
        "dropdowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdowns"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid"])},
        "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
        "modals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modals"])},
        "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range Slider"])},
        "progressbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress Bars"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placeholder"])},
        "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweet Alert"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabs & Accordions"])},
        "typography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typography"])},
        "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
        "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colors"])},
        "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightbox"])},
        "cropper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Cropper"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drawer"])}
      }
    },
    "forms": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "list": {
        "elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Elements"])},
        "layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Layouts"])},
        "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Validation"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Advanced"])},
        "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Editor"])},
        "fileupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form File Upload"])},
        "repeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Repeater"])},
        "wizard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Wizard"])},
        "mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Mask"])}
      }
    },
    "tables": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tables"])},
      "list": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Tables"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Table"])}
      }
    },
    "charts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
      "list": {
        "apex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apex Charts"])},
        "chartjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartjs Charts"])},
        "chartist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartist Charts"])},
        "echart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E Charts"])}
      }
    },
    "icons": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
      "list": {
        "boxicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxicons"])},
        "materialdesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material Design"])},
        "dripicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dripicons"])},
        "fontawesome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font Awesome"])}
      }
    },
    "maps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps"])},
      "list": {
        "googlemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps"])},
        "leafletmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaflet Maps"])}
      }
    },
    "multilevel": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Level"])},
      "list": {
        "level1": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 1.1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 1.2"])},
          "level2": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2.1"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level 2.2"])}
          }
        }
      }
    }
  }
}