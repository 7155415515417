export default {
  "navbar": {
    "search": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher..."])}
    },
    "dropdown": {
      "megamenu": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mega Menu"])},
        "uicontent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composants de l'interface utilisateur"])},
          "list": {
            "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boite à lumière"])},
            "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curseur de plage"])},
            "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweet Alert"])},
            "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])},
            "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formes"])},
            "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les tables"])},
            "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques"])}
          }
        },
        "application": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
          "list": {
            "ecommerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce électronique"])},
            "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
          }
        },
        "extrapages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages supplémentaires"])},
          "list": {
            "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale légère"])},
            "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale compacte"])},
            "horizontallayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition horizontale"])},
            "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien"])},
            "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])},
            "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronologie"])},
            "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])}
          }
        }
      },
      "site": {
        "list": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitHub"])},
          "bitbucket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitbucket"])},
          "dribbble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dribble"])},
          "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropbox"])},
          "mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Chimp"])},
          "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molle"])}
        }
      },
      "notification": {
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
        "order": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande est passée"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si plusieurs langues fusionnent la grammaire"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a 3 minutes"])}
        },
        "james": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James Lemire"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It will seem like simplified English."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a 1 heure"])}
        },
        "item": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre article est expédié"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si plusieurs langues fusionnent la grammaire"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a 3 minutes"])}
        },
        "salena": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salena Layfield"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a skeptical Cambridge friend of mine occidental."])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a 1 heure"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus.."])}
      },
      "henry": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henri"])},
        "list": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
          "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon portefeuille"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
          "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écran verrouillé"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
        }
      }
    }
  },
  "menuitems": {
    "menu": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
    },
    "dashboards": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableaux de bord"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04"])},
      "list": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
        "saas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saas"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])}
      }
    },
    "layouts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition"])},
      "list": {
        "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontale"])},
        "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale légère"])},
        "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale compacte"])},
        "iconsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale des icônes"])},
        "boxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposition en boîte"])},
        "coloredsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre latérale colorée"])},
        "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verticale"])},
        "lighttopbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barre supérieure légère"])},
        "coloredheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En-tête coloré"])}
      }
    },
    "apps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])}
    },
    "calendar": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendrier"])}
    },
    "chat": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bavarder"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle"])}
    },
    "filemanager": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionnaire de fichiers"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle"])}
    },
    "ecommerce": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce électronique"])},
      "list": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des produits"])},
        "productdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Detail"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
        "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clients"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chariot"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
        "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasins"])},
        "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un produit"])}
      }
    },
    "crypto": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto"])},
      "list": {
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
        "buy/sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter vendre"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange"])},
        "lending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
        "kycapplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application KYC"])},
        "icolanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICO Landing"])}
      }
    },
    "email": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "list": {
        "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte de réception"])},
        "reademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire l'e-mail"])},
        "template": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles"])},
          "list": {
            "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action de base"])},
            "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email d'alerte"])},
            "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de facturation"])}
          }
        }
      }
    },
    "invoices": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
      "list": {
        "invoicelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
        "invoicedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail de la facture"])}
      }
    },
    "projects": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets"])},
      "list": {
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille de projets"])},
        "projectlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des projets"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du projet"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau"])}
      }
    },
    "tasks": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tâches"])},
      "list": {
        "tasklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de tâches"])},
        "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau Kanban"])},
        "createtask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une tâche"])}
      }
    },
    "contacts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
      "list": {
        "usergrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille utilisateur"])},
        "userlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste d'utilisateur"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
      }
    },
    "blog": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle"])},
      "list": {
        "bloglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des blogs"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grille de blog"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du blog"])}
      }
    },
    "pages": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])}
    },
    "authentication": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle"])},
      "list": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
        "recoverpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mot de passe"])},
        "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écran verrouillé"])},
        "login-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'identifier 2"])},
        "register-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire 2"])},
        "recoverpwd-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mot de passe 2"])},
        "lockscreen-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écran verrouillé 2"])},
        "confirm-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le courrier"])},
        "confirm-mail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le courrier 2"])},
        "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'E-mail"])},
        "verification-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'E-mail 2"])},
        "verification-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification en deux étapes"])},
        "verification-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification en deux étapes 2"])}
      }
    },
    "utility": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilitaire"])},
      "list": {
        "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page de démarrage"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chronologie"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarification"])},
        "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur 404"])},
        "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur 500"])},
        "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])}
      }
    },
    "components": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composantes"])}
    },
    "uielements": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments de l'interface utilisateur"])},
      "list": {
        "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutons"])},
        "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes"])},
        "carousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrousel"])},
        "dropdowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste déroulante"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la grille"])},
        "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
        "modals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modals"])},
        "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curseur de plage"])},
        "progressbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barres de progression"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace réservé"])},
        "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweet Alert"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablatures et accordéons"])},
        "typography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typographie"])},
        "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générale"])},
        "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleurs"])},
        "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boite à lumière"])},
        "cropper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recadrage d'image"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiroir"])}
      }
    },
    "forms": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formes"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "list": {
        "elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments de formulaire"])},
        "layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositions de formulaire"])},
        "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation de formulaire"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire avancé"])},
        "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur de formulaires"])},
        "fileupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement de fichier de formulaire"])},
        "repeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répétiteur de formulaire"])},
        "wizard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant de formulaire"])},
        "mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masque de forme"])}
      }
    },
    "tables": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les tables"])},
      "list": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableaux de base"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau avancé"])}
      }
    },
    "charts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphiques"])},
      "list": {
        "apex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique Apex"])},
        "chartjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique Chartjs"])},
        "chartist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique Chartist"])},
        "echart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique E"])}
      }
    },
    "icons": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icônes"])},
      "list": {
        "boxicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxicons"])},
        "materialdesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception matérielle"])},
        "dripicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dripicons"])},
        "fontawesome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police géniale"])}
      }
    },
    "maps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])},
      "list": {
        "googlemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps"])},
        "leafletmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes de dépliant"])}
      }
    },
    "multilevel": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi niveaux"])},
      "list": {
        "level1": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 1.1"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 1.2"])},
          "level2": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 2.1"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau 2.2"])}
          }
        }
      }
    }
  }
}