export default {
  "navbar": {
    "search": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索..."])}
    },
    "dropdown": {
      "megamenu": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超级菜单"])},
        "uicontent": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI组件"])},
          "list": {
            "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灯箱"])},
            "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范围滑块"])},
            "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甜蜜警报"])},
            "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评分"])},
            "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["形式"])},
            "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桌子"])},
            "charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表"])}
          }
        },
        "application": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用领域"])},
          "list": {
            "ecommerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子商务"])},
            "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日历"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
            "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专案"])},
            "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
            "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络人"])}
          }
        },
        "extrapages": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外页面"])},
          "list": {
            "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["侧边灯"])},
            "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紧凑型侧边栏"])},
            "horizontallayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水平布局"])},
            "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保养"])},
            "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快来了"])},
            "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间线"])},
            "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])}
          }
        }
      },
      "site": {
        "list": {
          "github": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的GitHub"])},
          "bitbucket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比特桶"])},
          "dribbble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运球"])},
          "dropbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投寄箱"])},
          "mailchimp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮件黑猩猩"])},
          "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["松弛"])}
        }
      },
      "notification": {
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知事项"])},
        "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看全部"])},
        "order": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的订单已下达"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果几种语言合并了语法"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3分钟前"])}
        },
        "james": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詹姆斯·勒米尔"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好像是简体英语。"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1小时前"])}
        },
        "item": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的物品已发货"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果几种语言合并了语法"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3分钟前"])}
        },
        "salena": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萨莱娜·莱菲尔德"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作为西方的一个怀疑的剑桥朋友。"])},
          "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1小时前"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装载更多.."])}
      },
      "henry": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亨利"])},
        "list": {
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
          "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的钱包"])},
          "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设定值"])},
          "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁屏"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])}
        }
      }
    }
  },
  "menuitems": {
    "menu": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菜单"])}
    },
    "dashboards": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仪表板"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04"])},
      "list": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
        "saas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["萨斯"])},
        "crypto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密货币"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客"])}
      }
    },
    "layouts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版面"])},
      "list": {
        "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卧式"])},
        "lightsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["侧边灯"])},
        "compactsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紧凑型侧边栏"])},
        "iconsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图标侧栏"])},
        "boxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒装版式"])},
        "coloredsidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彩色边栏"])},
        "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["垂直"])},
        "lighttopbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轻顶杆"])},
        "coloredheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["彩色标题"])}
      }
    },
    "apps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用"])}
    },
    "calendar": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日历"])}
    },
    "chat": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聊天室"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新"])}
    },
    "filemanager": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件管理器"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新"])}
    },
    "ecommerce": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子商务"])},
      "list": {
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品展示"])},
        "productdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品明细"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
        "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顾客"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大车"])},
        "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
        "shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])},
        "addproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加产品"])}
      }
    },
    "crypto": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密货币"])},
      "list": {
        "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
        "buy/sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖"])},
        "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
        "lending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借贷"])},
        "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
        "kycapplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC应用"])},
        "icolanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICO登陆"])}
      }
    },
    "email": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "list": {
        "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件箱"])},
        "reademail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读电子邮件"])},
        "template": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范本"])},
          "list": {
            "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本动作"])},
            "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报电子邮件"])},
            "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐单电邮"])}
          }
        }
      }
    },
    "invoices": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票"])},
      "list": {
        "invoicelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票清单"])},
        "invoicedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票明细"])}
      }
    },
    "projects": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专案"])},
      "list": {
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目网格"])},
        "projectlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目清单"])},
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目概况"])},
        "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新的"])}
      }
    },
    "tasks": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务"])},
      "list": {
        "tasklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务列表"])},
        "kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看板委员会"])},
        "createtask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建任务"])}
      }
    },
    "contacts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络人"])},
      "list": {
        "usergrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户网格"])},
        "userlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户清单"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])}
      }
    },
    "blog": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新"])},
      "list": {
        "bloglist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客列表"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客网格"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["博客详细信息"])}
      }
    },
    "pages": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页数"])}
    },
    "authentication": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证方式"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新"])},
      "list": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄存器"])},
        "recoverpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复密码"])},
        "lockscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁屏"])},
        "login-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录2"])},
        "register-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册2"])},
        "recoverpwd-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找回密码2"])},
        "lockscreen-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁屏2"])},
        "confirm-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认邮件"])},
        "confirm-mail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认邮件2"])},
        "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件验证"])},
        "verification-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件验证2"])},
        "verification-step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两步验证"])},
        "verification-step-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两步验证2"])}
      }
    },
    "utility": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["效用"])},
      "list": {
        "starter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起始页"])},
        "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保养"])},
        "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间线"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价钱"])},
        "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误404"])},
        "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误500"])},
        "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快来了"])}
      }
    },
    "components": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组件"])}
    },
    "uielements": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI元素"])},
      "list": {
        "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["警报"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纽扣"])},
        "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌"])},
        "carousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮播"])},
        "dropdowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下拉菜单"])},
        "grid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格网"])},
        "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图片"])},
        "modals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模态"])},
        "rangeslider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["范围滑块"])},
        "progressbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进度条"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["占位符"])},
        "sweetalert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甜蜜警报"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签和手风琴"])},
        "typography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版式"])},
        "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
        "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色彩"])},
        "lightbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灯箱"])},
        "cropper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖像裁剪器"])},
        "drawer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽屉"])}
      }
    },
    "forms": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["形式"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "list": {
        "elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表单元素"])},
        "layouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表单布局"])},
        "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格验证"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格高级"])},
        "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格编辑器"])},
        "fileupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格文件上传"])},
        "repeater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表单中继器"])},
        "wizard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表单向导"])},
        "mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格蒙版"])}
      }
    },
    "tables": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桌子"])},
      "list": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本表"])},
        "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高级表"])}
      }
    },
    "charts": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表"])},
      "list": {
        "apex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顶点图"])},
        "chartjs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartjs图表"])},
        "chartist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宪章图"])},
        "echart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子图表"])}
      }
    },
    "icons": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图示"])},
      "list": {
        "boxicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxicons"])},
        "materialdesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["材料设计"])},
        "dripicons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滴灌"])},
        "fontawesome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字体很棒"])}
      }
    },
    "maps": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地图"])},
      "list": {
        "googlemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谷歌地图"])},
        "leafletmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傳單地圖"])}
      }
    },
    "multilevel": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多层次"])},
      "list": {
        "level1": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1级"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.2级"])},
          "level2": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.1级"])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.2级"])}
          }
        }
      }
    }
  }
}